import axios from 'axios'
import { NetworkIds } from 'blockchain/networks'
import { ProductHubLoadingState } from 'features/productHub/components'
import { ProductHubPromoCardsController } from 'features/productHub/controls'
import { ProductHubContentController } from 'features/productHub/controls/ProductHubContentController'
import { ALL_ASSETS } from 'features/productHub/meta'
import {
  ProductHubFilters,
  ProductHubProductType,
  ProductHubSupportedNetworks,
} from 'features/productHub/types'
import { useWalletManagement } from 'features/web3OnBoard'
import { PromoCardsCollection } from 'handlers/product-hub/types'
import { WithLoadingIndicator } from 'helpers/AppSpinner'
import { LendingProtocol } from 'lendingProtocols'
import React, { FC, Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import {
  fromWei,
  getLBROracle,
  getLybraMintPoolContract,
  getLybraStakePoolContract,
  getMatchContract,
  getMiningIncentiveContract,
  getTokenContract,
  METCH_CONTRACT_ADDRESS,
  stETH_MINT_POOL,
  toWei,
} from 'utils/match'

import fakeData from './fakeData.json'

interface ProductHubViewProps {
  initialNetwork?: ProductHubSupportedNetworks[]
  initialProtocol?: LendingProtocol[]
  intro?: (selectedProduct: ProductHubProductType, selectedToken: string) => ReactNode
  headerGradient?: [string, string, ...string[]]
  product: ProductHubProductType
  promoCardsCollection: PromoCardsCollection
  token?: string
  url?: string
  limitRows?: number
}

const isOkxApp = typeof window !== 'undefined' && /OKApp/i.test(navigator.userAgent)

export const ProductHubView: FC<ProductHubViewProps> = ({
  initialNetwork,
  initialProtocol,
  headerGradient = ['#007DA3', '#E7A77F', '#E97047'],
  product,
  promoCardsCollection,
  intro,
  token,
  url,
  limitRows,
}) => {
  const [data, setData] = useState<any>(fakeData)
  const { wallet, chainId } = useWalletManagement()
  const [matchContract, setMathContract] = useState<any>(null)
  const [mintPoolContract, setMintPoolContract] = useState<any>(null)
  const [eUSDRebase, setEUSDRebase] = useState<number>(0)
  const [swapFeeApr, setSwapFeeApr] = useState<number>(0)
  const [dlpAirdropApr, setDlpAirdropApr] = useState<number>(0)
  const [ethAirdropApr, setEthAirdropApr] = useState<number>(0)

  const getOnlineData = () => {
    axios('https://api.matchfinance.io/apr')
      .then((res: any) => {
        console.log(res)
        const tvl = parseFloat(
          isOkxApp
            ? res.data.data.total_liquidity.slice(1).split(',').join('')
            : res.data.data.total_liquidity.replaceAll(/[^0-9.]/g, ''),
        )
        const fees = parseFloat(
          isOkxApp
            ? res.data.data.fees.slice(1).split(',').join('')
            : res.data.data.fees.replaceAll(/[^0-9.]/g, ''),
        )
        console.log('fees', res.data.data.fees.replaceAll(/[^0-9.]/g, ''),fees)
        setSwapFeeApr(tvl ? (fees * 365) / tvl : 0)
        // setDlpAirdropApr(parseFloat(res.data.data.airdropApr.dlp)*100)
        // setEthAirdropApr(parseFloat(res.data.data.airdropApr.eth)*100)
        setEUSDRebase(parseFloat(res.data.data.apy))
      })
      .catch((e: any) => {
        console.error(e)
      })
  }
  useEffect(() => {
    if (
      data.promoCards.earn.default[0].data[0].value === 'N.A.' ||
      data.table[0].APRComponents.dLPSwapFee !== undefined
    )
      return
    console.log(
      'data.promoCards.earn.default[0].data.value',
      data.promoCards.earn.default[0].data[0].value,
    )
    const dlpApr = parseFloat(data.promoCards.earn.default[0].data[0].value)
    const ethApr = parseFloat(data.promoCards.earn.default[1].data[0].value)
    const newDlpApr = dlpApr + swapFeeApr * 100 + dlpAirdropApr
    const newEthApr = ethApr + eUSDRebase + ethAirdropApr
    console.log(dlpApr, newDlpApr, ethApr, newEthApr)
    setData((prevData: any) => {
      return {
        promoCards: {
          ...prevData.promoCards,
          earn: {
            ...prevData.promoCards.earn,
            default: [
              {
                ...prevData.promoCards.earn.default[0],
                data: [{ label: 'Live', value: `${newDlpApr.toFixed(2)}%` }],
              },
              {
                ...prevData.promoCards.earn.default[1],
                data: [
                  {
                    label: 'Live',
                    value: `${newEthApr.toFixed(2)}%`,
                  },
                ],
              },
              prevData.promoCards.earn.default[2],
            ],
          },
        },
        table: [
          {
            ...prevData.table[0],
            APR: `${newDlpApr.toFixed(2)}%`,
            APRComponents: { ...prevData.table[0].APRComponents, dLPSwapFee: swapFeeApr, airdrop: dlpAirdropApr },
          },
          {
            ...prevData.table[1],
            APR: `${newEthApr.toFixed(2)}%`,
            APRComponents: { ...prevData.table[1].APRComponents, eUSDRebase: `${eUSDRebase}%`, airdrop: ethAirdropApr },
          },
        ],
      }
    })
  }, [eUSDRebase, data])
  useEffect(() => {
    ;(window as any).onRewardsSet = (amount: number, miningReward: number) => {
      console.log('onRewardsSet', amount, miningReward)
      setData((prevData: any) => ({
        ...prevData,
        table: [
          {
            ...prevData.table[0],
            Rewards: `${amount.toFixed(2)} mesLBR`,
          },
          {
            ...prevData.table[1],
            Rewards: `${miningReward.toFixed(2)} mesLBR`,
          },
        ],
      }))
    }
    ;(window as any).userRewards !== undefined &&
      setData((prevData: any) => ({
        ...prevData,
        table: [
          {
            ...prevData.table[0],
            Rewards: `${(window as any).userRewards.toFixed(2)} mesLBR`,
          },
          {
            ...prevData.table[1],
            Rewards: `${(window as any).miningReward.toFixed(2)} mesLBR`,
          },
        ],
      }))
    async function initContract() {
      console.log('get contract')
      const matchContract = await getMatchContract()
      console.log(matchContract)
      const mintPoolContract = await getLybraMintPoolContract()
      console.log(mintPoolContract)
      setMathContract(matchContract)
      setMintPoolContract(mintPoolContract)
    }
    initContract().catch((e) => console.error(e))
    getOnlineData()
  }, [])
  async function getdLPAmount() {
    console.log('get dlp amount')
    const lbrOracle = await getLBROracle()
    console.log('lbrOracle', lbrOracle)
    const stakePoolContract = await getLybraStakePoolContract()
    console.log('stakePoolContract', stakePoolContract)
    const miningIncentiveContract = await getMiningIncentiveContract()
    console.log('miningIncentiveContract', miningIncentiveContract)

    const [stakedWei, myStakedWei, totalStakedWei] = await Promise.all([
      matchContract.methods.totalStaked().call(),
      wallet?.address ? matchContract.methods.staked(wallet?.address).call() : '0',
      stakePoolContract.methods.totalSupply().call(),
    ])
    console.log('totalStakedWei', totalStakedWei)

    let [
      stakedValueWei,
      myStakedValueWei,
      totalStakedValueWei,
      rewardRatioWei,
      lBRPriceWei,
      miningRewardRatioWei,
      matchMintedWei,
      totalMintedWei,
    ] = await Promise.all([
      matchContract.methods.getLpValue(stakedWei).call(),
      matchContract.methods.getLpValue(myStakedWei).call(),
      matchContract.methods.getLpValue(totalStakedWei).call(),
      stakePoolContract.methods.rewardRatio().call(),
      lbrOracle.methods.latestRoundData().call(),
      miningIncentiveContract.methods.rewardRatio().call(),
      miningIncentiveContract.methods.stakedOf(METCH_CONTRACT_ADDRESS).call(),
      miningIncentiveContract.methods.totalStaked().call(),
    ])

    console.log('dlp info gotten', stakedValueWei, myStakedValueWei, totalStakedValueWei)
    const staked = fromWei(stakedValueWei)
    const myStaked = fromWei(myStakedValueWei)
    const totalStaked = fromWei(totalStakedValueWei)
    console.log('staked', staked)
    // APR: 1 year reward amount * LBR price / total staked dLP value
    const apr = (31536000 * fromWei(rewardRatioWei) * (Number(lBRPriceWei[1]) / 1e8)) / totalStaked

    const miningRewardValue =
      (31536000 *
        fromWei(miningRewardRatioWei) *
        (Number(lBRPriceWei[1]) / 1e8) *
        fromWei(matchMintedWei)) /
      fromWei(totalMintedWei)

    const sharingApr = staked ? (miningRewardValue * 0.1) / staked : 0
    const aPRComponents = { dlp: apr, eusd: sharingApr, match_treasury: sharingApr }
    return { apr: `${((apr + sharingApr) * 100).toFixed(2)}%`, staked, myStaked, aPRComponents }
  }
  async function getETHAmount() {
    console.log('eth amount getting')
    const lbrOracle = await getLBROracle()
    const miningIncentiveContract = await getMiningIncentiveContract()
    const wstETHMintPool = await getLybraMintPoolContract('wstETH')
    const wbETHMintPool = await getLybraMintPoolContract('wbETH')
    const rETHMintPool = await getLybraMintPoolContract('rETH')
    const eUSDOld = await getTokenContract('eUSDOld')
    const [
      rewardRatioWei,
      // matchMintedWei,
      // totalMintedWei,
      lBRPriceWei,
      suppliedWei,
      totalSuppliedWei,
      priceWei,
      stETHBorrowedWei,
      wstETHBorrowedWei,
      wbETHBorrowedWei,
      rETHBorrowedWei,
      eUSDOldBorrowedWei,
    ] = await Promise.all([
      miningIncentiveContract.methods.rewardRatio().call(),
      // miningIncentiveContract.methods.stakedOf(METCH_CONTRACT_ADDRESS).call(),
      // miningIncentiveContract.methods.totalStaked().call(),
      lbrOracle.methods.latestRoundData().call(),
      wallet?.address
        ? matchContract.methods.supplied(stETH_MINT_POOL, wallet?.address).call()
        : '0',
      matchContract.methods.totalSupplied(stETH_MINT_POOL).call(),
      mintPoolContract.methods.getAssetPrice().call(),
      mintPoolContract.methods.getPoolTotalCirculation().call(),
      wstETHMintPool.methods.getPoolTotalCirculation().call(),
      wbETHMintPool.methods.getPoolTotalCirculation().call(),
      rETHMintPool.methods.getPoolTotalCirculation().call(),
      eUSDOld.methods.totalSupply().call(),
    ])
    console.log('eth info gotten', rewardRatioWei)
    const price = fromWei(priceWei)
    const balance = fromWei(suppliedWei) * price
    const totalLybraMinted =
      fromWei(stETHBorrowedWei) +
      fromWei(wstETHBorrowedWei) +
      fromWei(wbETHBorrowedWei) +
      fromWei(rETHBorrowedWei) +
      fromWei(eUSDOldBorrowedWei)
    const tvl = fromWei(totalSuppliedWei) * price
    // APR: 1 year reward amount * LBR price / total minted value (APR displayed on Lybra)
    const apr =
      (31536000 * fromWei(rewardRatioWei) * (Number(lBRPriceWei[1]) / 1e8)) / totalLybraMinted
    return { tvl, balance, apr }
  }
  async function initData() {
    console.log('init data')
    try {
      const dLpData = await getdLPAmount()
      const ethData = await getETHAmount()
      setData((prevData: any) => ({
        promoCards: {
          ...prevData.promoCards,
          earn: {
            ...prevData.promoCards.earn,
            default: [
              {
                ...prevData.promoCards.earn.default[0],
                data: [{ label: 'Live', value: dLpData.apr }],
              },
              {
                ...prevData.promoCards.earn.default[1],
                data: [
                  {
                    label: 'Live',
                    value: ethData.apr ? `${(ethData.apr * 80).toFixed(2)}%` : 'N.A.',
                  },
                ],
              },
              prevData.promoCards.earn.default[2],
            ],
          },
        },
        table: [
          {
            ...prevData.table[0],
            APR: dLpData.apr,
            TVL: `$${parseFloat(dLpData.staked.toFixed(0)).toLocaleString()}`,
            Balance: `$${parseFloat(dLpData.myStaked.toFixed(2)).toLocaleString()}`,
            APRComponents: dLpData.aPRComponents,
          },
          {
            ...prevData.table[1],
            TVL: `$${parseFloat(ethData.tvl.toFixed(0)).toLocaleString()}`,
            Balance: `$${parseFloat(ethData.balance.toFixed(2)).toLocaleString()}`,
            APR: ethData.apr ? `${(ethData.apr * 80).toFixed(2)}%` : '--',
            APRComponents: {
              mintPool: ethData.apr ? `${(ethData.apr * 100).toFixed(2)}%` : '--',
              shared: ethData.apr ? `-${(ethData.apr * 20).toFixed(2)}%` : '--',
            },
          },
        ],
      }))
    } catch (e) {
      console.log('init error', e)
    }
  }

  useEffect(() => {
    console.log('start init data', matchContract, mintPoolContract)
    // if (chainId !== NetworkIds.MAINNET) return
    if (matchContract && mintPoolContract) {
      void initData()
    }
  }, [matchContract, wallet?.address, mintPoolContract])

  const defaultFilters = useMemo(
    () => ({
      or: [],
      and: {
        ...(initialNetwork && { network: initialNetwork }),
        ...(initialProtocol && { protocol: initialProtocol }),
      },
    }),
    [initialNetwork, initialProtocol],
  )
  const [selectedProduct, setSelectedProduct] = useState<ProductHubProductType>(
    ProductHubProductType.Earn,
  )
  const [selectedToken, setSelectedToken] = useState<string>(token || ALL_ASSETS)
  const [selectedFilters, setSelectedFilters] = useState<ProductHubFilters>(defaultFilters)

  return (
    <Fragment key={product}>
      <Box
        id="product-hub"
        sx={{
          position: 'relative',
          mt: [2, null, '20px'],
          mb: [4, null, '48px'],
          scrollMarginTop: '48px',
          textAlign: 'center',
          zIndex: 3,
        }}
      >
        <Flex
          sx={{
            fontSize: 36,
            color: '#17344F',
            fontWeight: 500,
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'poppins',
            flexWrap: 'wrap',
            '@media screen and (max-width: 460px)': {
              fontSize: '19px',
              img: {
                width: '20px',
              },
            },
          }}
        >
          <Text
            style={{
              background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              margin: '0 12px',
            }}
          >
            Yield
          </Text>
          Aggregator for
          <img src="/static/icons/tokens/dLP.svg" style={{ marginLeft: '16px' }} />
          <Text
            style={{
              background: 'linear-gradient(137deg, #3393FF 13.99%, #E2989D 85.87%)',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              marginLeft: '7px',
            }}
          >
            Lybra Finance
          </Text>
        </Flex>
        <Text mt="24px" color="#17344F" style={{ fontSize: 16 }}>
          Match between LBR/ETH LP and eUSD
        </Text>
      </Box>
      <WithLoadingIndicator value={[data]} customLoader={<ProductHubLoadingState />}>
        {([_data]) => (
          <>
            <ProductHubPromoCardsController
              promoCardsData={_data.promoCards}
              selectedProduct={selectedProduct}
              selectedToken={selectedToken}
            />
            <ProductHubContentController
              initialNetwork={initialNetwork}
              initialProtocol={initialProtocol}
              selectedFilters={selectedFilters}
              selectedProduct={selectedProduct}
              selectedToken={selectedToken}
              tableData={_data.table}
              onChange={setSelectedFilters}
              limitRows={limitRows}
            />
          </>
        )}
      </WithLoadingIndicator>
    </Fragment>
  )
}
