import { PromoCard } from 'components/PromoCard'
import { ProductHubProductType, ProductHubPromoCards } from 'features/productHub/types'
import React, { FC, useMemo } from 'react'
import { Grid } from 'theme-ui'

interface ProductHubPromoCardsControllerProps {
  promoCardsData: ProductHubPromoCards
  selectedProduct: ProductHubProductType
  selectedToken: string
}

const cardBg = [
  'linear-gradient(180deg, rgba(80, 101, 228, 0.50) 0%, rgba(255, 255, 255, 0.20) 42.71%)',
  'linear-gradient(180deg, #8BC9DE 0%, rgba(255, 255, 255, 0.50) 43.07%)',
  'linear-gradient(180deg, rgba(246, 151, 146, 0.50) 0%, rgba(255, 255, 255, 0.20) 42.71%)',
]

export const ProductHubPromoCardsController: FC<ProductHubPromoCardsControllerProps> = ({
  promoCardsData,
  selectedProduct,
  selectedToken,
}) => {
  const promoCards = useMemo(
    () => promoCardsData[selectedProduct].default,
    [promoCardsData, selectedProduct],
  )

  return (
    <>
      {promoCards.length > 0 && (
        <Grid columns={[1, null, 2, 3]} gap={3} sx={{ mb: 4 }}>
          {promoCards.map((promoCard, i) => (
            <PromoCard
              sx={{
                background: cardBg[i],
              }}
              key={`${selectedProduct}-${i}`}
              {...promoCard}
            />
          ))}
        </Grid>
      )}
    </>
  )
}
