import { InfoCard } from 'components/InfoCard'
import { ProductHubProductType } from 'features/productHub/types'
import { ProductHubView } from 'features/productHub/views'
import { EXTERNAL_LINKS } from 'helpers/applicationLinks'
import { useFeatureToggle } from 'helpers/useFeatureToggle'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { Box, Flex, Grid, Image, Text } from 'theme-ui'
import { slideInAnimation } from 'theme/animations'

const cardStyle: any = {
  padding: '30px',
  borderRadius: '16px',
  background: '#F1F3FD',
  width: '288px',
  height: '331px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    background: 'linear-gradient(180deg, #F1F3FD 0%, #D4D8F5 100%)',
  },
  '@media screen and (max-width: 500px)': {
    width: '100%',
    height: '200px',
    mb: '8px',
  },
}
const cardTextStyle = {
  mt: '12px',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'body',
  color: '#596F78',
}
const learnMoreStyle = { fontSize: '16px', lineHeight: '22px', fontFamily: 'body', fontWeight: 600 }

export function HomepageView() {
  const ajnaSafetySwitchOn = useFeatureToggle('AjnaSafetySwitch')
  const { t } = useTranslation()
  // const { data: oasisStats } = useOasisStats()
  const openPage = (url: string) => window.open(url)

  return (
    <Box
      sx={{
        flex: 1,
        ...slideInAnimation,
        position: 'relative',
        animationDuration: '0.4s',
        animationTimingFunction: 'cubic-bezier(0.7, 0.01, 0.6, 1)',
      }}
    >
      <Box>
        <ProductHubView
          product={ProductHubProductType.Earn}
          promoCardsCollection={ajnaSafetySwitchOn ? 'Home' : 'HomeWithAjna'}
          limitRows={10}
        />
      </Box>
      <Box mt="48px">
        <Text
          sx={{
            textAlign: 'center',
            fontSize: '36px',
            fontWeight: 500,
            lineHeight: '54px',
            fontFamily: 'Poppins',
          }}
        >
          Quick Links
        </Text>
        <Flex sx={{ mt: '48px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Flex sx={cardStyle} onClick={() => openPage('https://twitter.com/matchfinance?s=21')}>
            <Box>
              <Flex sx={{ alignItems: 'center' }}>
                <Image src="/static/icons/socialMedia/X.svg" />
                <Text sx={{ ml: '15px', fontWeight: 700 }}>Twitter</Text>
              </Flex>
              <Text sx={cardTextStyle}>Explore more exciting content and real-time updates.</Text>
            </Box>
            <Flex>
              <Text sx={learnMoreStyle}>Follow us</Text>
              <Image src="/static/icons/arrow_right.svg" ml="6px" />
            </Flex>
          </Flex>
          <Flex sx={cardStyle} onClick={() => openPage(EXTERNAL_LINKS.DISCORD)}>
            <Box>
              <Flex sx={{ alignItems: 'center' }}>
                <Image src="/static/icons/socialMedia/Discord.svg" />
                <Text sx={{ ml: '15px', fontWeight: 700 }}>Discord</Text>
              </Flex>
              <Text sx={cardTextStyle}>Join the conversation today</Text>
            </Box>
            <Flex>
              <Text sx={learnMoreStyle}>Contact Us</Text>
              <Image src="/static/icons/arrow_right.svg" ml="6px" />
            </Flex>
          </Flex>
          <Flex
            sx={cardStyle}
            onClick={() => window.open('https://match-finance.gitbook.io/whitepaper/')}
          >
            <Box>
              <Flex sx={{ alignItems: 'center' }}>
                <Image src="/static/icons/match_logo.svg" sx={{ width: '36px' }} />
                <Text sx={{ ml: '15px', fontWeight: 700 }}>Docs</Text>
              </Flex>
              <Text sx={cardTextStyle}>Explore more Docs about Match Finance.</Text>
            </Box>
            <Flex>
              <Text sx={learnMoreStyle}>Explore more</Text>
              <Image src="/static/icons/arrow_right.svg" ml="6px" />
            </Flex>
          </Flex>
          <Flex sx={cardStyle} onClick={() => openPage(EXTERNAL_LINKS.GITHUB)}>
            <Box>
              <Flex sx={{ alignItems: 'center' }}>
                <Image src="/static/icons/socialMedia/Github.svg" />
                <Text sx={{ ml: '15px', fontWeight: 700 }}>Github</Text>
              </Flex>
              <Text sx={cardTextStyle}>Explore more exciting content and real-time updates.</Text>
            </Box>
            <Flex>
              <Text sx={learnMoreStyle}>Explore more</Text>
              <Image src="/static/icons/arrow_right.svg" ml="6px" />
            </Flex>
          </Flex>
        </Flex>
      </Box>
      {/* <Box
        sx={{
          mb: 30,
        }}
      >
        <Text
          as="p"
          variant="header2"
          sx={{ textAlign: 'center', mt: [6, 6, '65px'], mb: 4, fontFamily: 'Poppins' }}
        >
          {t('landing.info-cards.have-some-questions')}
        </Text>
        <Grid
          gap={4}
          sx={{
            margin: 'auto',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
          }}
        >
          <InfoCard
            title="Learn Lybra Finance"
            subtitle="Deep dive into Lybra Finance functionalities and glossary"
            links={[
              {
                href: 'https://match-finance.gitbook.io/whitepaper/lybra-finance-ecosystem',
                text: t('landing.info-cards.support.faq'),
              },
              {
                href: 'https://discord.com/invite/mgyq3PhdJg',
                text: t('landing.info-cards.support.discord'),
              },
              {
                href: 'https://twitter.com/LybraFinance',
                text: t('landing.info-cards.support.twitter'),
              },
            ]}
            backgroundGradient="linear-gradient(0deg, #fff 0%, #fff 100%)"
            backgroundImage="/static/img/learn.svg"
            sx={{ backgroundSize: 'cover' }}
          />
          <InfoCard
            title="Learn Match Finance"
            subtitle="Deep dive into Match Finance functionalities and glossary"
            links={[
              {
                href: 'https://match-finance.gitbook.io/whitepaper/faq',
                text: t('landing.info-cards.support.faq'),
              },
              {
                href: EXTERNAL_LINKS.DISCORD,
                text: t('landing.info-cards.support.discord'),
              },
              {
                href: EXTERNAL_LINKS.TWITTER,
                text: t('landing.info-cards.support.twitter'),
              },
            ]}
            backgroundGradient="linear-gradient(0deg, #fff 0%, #fff 100%)"
            backgroundImage="/static/img/support.svg"
            sx={{ backgroundSize: 'cover' }}
          />
        </Grid>
      </Box> */}
    </Box>
  )
}
